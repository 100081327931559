/*
 *  new_map
 *
 *  This function will render a Google Map onto the selected jQuery element
 *
 *  @type	function
 *  @date	8/11/2013
 *  @since	4.3.0
 *
 *  @param	$el (jQuery element)
 *  @return	n/a
 */

function new_map($el) {

    // var
    var $markers = $el.find('.marker');


    // vars
    var args = {
        zoom: 16,
        center: new google.maps.LatLng(0, 0),
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles:[
            {
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#f5f5f5"
                }
              ]
            },
            {
              "elementType": "labels.icon",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#616161"
                }
              ]
            },
            {
              "elementType": "labels.text.stroke",
              "stylers": [
                {
                  "color": "#f5f5f5"
                }
              ]
            },
            {
              "featureType": "administrative",
              "stylers": [
                {
                  "visibility": "on"
                }
              ]
            },
            {
              "featureType": "administrative.land_parcel",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#bdbdbd"
                }
              ]
            },
            {
              "featureType": "landscape",
              "stylers": [
                {
                  "visibility": "on"
                }
              ]
            },
            {
              "featureType": "poi",
              "stylers": [
                {
                  "visibility": "on"
                }
              ]
            },
            {
              "featureType": "poi",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#eeeeee"
                }
              ]
            },
            {
              "featureType": "poi",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#757575"
                }
              ]
            },
            {
              "featureType": "poi.business",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "poi.government",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "poi.medical",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "poi.park",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "poi.park",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#e5e5e5"
                }
              ]
            },
            {
              "featureType": "poi.park",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#9e9e9e"
                }
              ]
            },
            {
              "featureType": "poi.place_of_worship",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "poi.school",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "poi.sports_complex",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "road",
              "stylers": [
                {
                  "visibility": "on"
                }
              ]
            },
            {
              "featureType": "road",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#ffffff"
                }
              ]
            },
            {
              "featureType": "road.arterial",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#757575"
                }
              ]
            },
            {
              "featureType": "road.highway",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#dadada"
                }
              ]
            },
            {
              "featureType": "road.highway",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#616161"
                }
              ]
            },
            {
              "featureType": "road.local",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#9e9e9e"
                }
              ]
            },
            {
              "featureType": "transit",
              "stylers": [
                {
                  "visibility": "on"
                }
              ]
            },
            {
              "featureType": "transit.line",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#e5e5e5"
                }
              ]
            },
            {
              "featureType": "transit.station",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#eeeeee"
                }
              ]
            },
            {
              "featureType": "water",
              "stylers": [
                {
                  "visibility": "on"
                }
              ]
            },
            {
              "featureType": "water",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#c9c9c9"
                }
              ]
            },
            {
              "featureType": "water",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#9e9e9e"
                }
              ]
            }
          ]
    };


    // create map	        	
    var map = new google.maps.Map($el[0], args);


    // add a markers reference
    map.markers = [];


    // add markers
    $markers.each(function () {

        add_marker($(this), map);

    });


    // center map
    center_map(map);


    // return
    return map;

}

/*
 *  add_marker
 *
 *  This function will add a marker to the selected Google Map
 *
 *  @type	function
 *  @date	8/11/2013
 *  @since	4.3.0
 *
 *  @param	$marker (jQuery element)
 *  @param	map (Google Map object)
 *  @return	n/a
 */

function add_marker($marker, map) {

    // var
    var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));

    // create marker
    //var image = window.location.protocol + '//' + window.location.hostname + '/wp-content/themes/pullup-theme/assets/img/maker.png';
    var marker = new google.maps.Marker({
        position: latlng,
        map: map,
        //icon: image
    });

    // add to array
    map.markers.push(marker);

    // if marker contains HTML, add it to an infoWindow
    if ($marker.html()) {
        // create info window
        var infowindow = new google.maps.InfoWindow({
            content: $marker.html()
        });

        // show info window when marker is clicked
        google.maps.event.addListener(marker, 'click', function () {

            infowindow.open(map, marker);

        });
    }

}

/*
 *  center_map
 *
 *  This function will center the map, showing all markers attached to this map
 *
 *  @type	function
 *  @date	8/11/2013
 *  @since	4.3.0
 *
 *  @param	map (Google Map object)
 *  @return	n/a
 */

function center_map( map ) {

	// vars
	var bounds = new google.maps.LatLngBounds();

	// loop through all markers and create bounds
	$.each( map.markers, function( i, marker ){

		var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

		bounds.extend( latlng );

	});

	// only 1 marker?
	if( map.markers.length == 1 )
	{
		// set center of map
	    map.setCenter( bounds.getCenter() );
	    map.setZoom( 16 );
	}
	else
	{
		// fit to bounds
		map.fitBounds( bounds );
	}

}

function handleLocationError(browserHasGeolocation, infoWindow, pos) {
    infoWindow.setPosition(pos);
    infoWindow.setContent(browserHasGeolocation ?
        'Error: The Geolocation service failed.' :
        'Error: Your browser doesn\'t support geolocation.');
    infoWindow.open(map);
}

function searchByUserLocation(action) {
    $.ajax({
        type: 'POST',
        url: baseurl_ajax,
        data: {
            action: 'getCoordinates'
        },
        beforeSend: function () {
            $('#svg-loader').removeClass('d-none');
        },
        success: function (response) {
            var markersByDistance = [];
            var markers = response.data;
            var myLatlng = '';

            if (action == 'user_location') {
                myLatlng = {
                    lng: current_user_coordinates.lng,
                    lat: current_user_coordinates.lat
                };
            } else if (action == 'address') {
                var latlng = getCoordinatesFromAddress();
                myLatlng = {
                    lng: latlng.lng,
                    lat: latlng.lat
                };
            }

            for (var i = 0; i < markers.length; i++) {
                var marker = markers[i];


                // using pythagoras does not take into account curvature, 
                // but will work fine over small distances.
                // you can use more complicated trigonometry to 
                // take curvature into consideration
                var dx = Number(myLatlng.lng) - Number(marker.lng);
                var dy = Number(myLatlng.lat) - Number(marker.lat);
                var distance = Math.sqrt(dx * dx + dy * dy);

                markersByDistance[i] = marker;
                markersByDistance[i].distance = distance;
            }

            // sort the array... now the first 5 elements should be your closest points.
            sortByKey(markersByDistance, 'distance');
            var closestMarkers = markersByDistance.slice(0, 5);
            getClosestsStores(closestMarkers);
        },
        error: function (response) {
            console.log('Ocorreu um Erro!');
        }
    }).done(function () {
        $('#svg-loader').addClass('d-none');
    }).fail(function () {
        $('#svg-loader').addClass('d-none');
    });
}

function getClosestsStores(closestMarkers) {
    $.ajax({
        type: 'POST',
        url: baseurl_ajax,
        data: {
            action: 'closestStores',
            coordinates: closestMarkers
        },
        success: function (response) {
            $('.acf-map').empty();
            $('.lista-unidades').empty();
            $.each(response.data, function (key, marker) {
                $('.acf-map').append('<div class="marker" data-lat="' + marker.lat + '" data-lng="' + marker.lng + '"></div>');
                //if ($('#estado option:selected').val() != "" || $('#cidade option:selected').val() != "" || $('#cep').val() != "") {
                    $('.lista-unidades').append(
                        '<div class="card-unidade">' +
                        '<h3>' + marker.post_title + '</h3>' +
                        '<p>' + marker.address.rua + '<br>' + marker.address.bairro + ', ' + marker.address.estado + '</p>' +
                        '<div class="row  align-items-end">' +
                        '<div class="col-12 col-lg">' +
                        '<div class="telefone">' +
                        '<span>Tel: </span>' + marker.address.telefone +
                        '</div>' +
                        '<div class="whatsapp">' +
                        '<span>Whatsapp: </span>' + marker.address.whatsapp +
                        '</div>' +
                        '</div>' +
                        '<div class="mt-4 mt-lg-0 col-12 col-lg-auto">' +
                        '<a href="' + marker.url + '" class="btn btn-primary">Acesse o site ></a>' +
                        '</div>' +
                        '</div>' +
                        '</div>'
                    );
                //}
            });
            new_map($('.acf-map'));
        }
    });
}

function sortByKey(array, key) {
    return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
}

function getUserCoordinates() {
    $.ajax({
        type: 'POST',
        url: baseurl_ajax,
        data: {
            action: 'getGeoLocation'
        },
        success: function (response) {
            current_user_coordinates = response.data;
        }
    }).done(function () {
        searchByUserLocation('user_location');
    });
}

function getCoordinatesFromAddress() {

    var address = '';
    var geocoder = new google.maps.Geocoder();

    if ($('#cidade option:selected').val() != '') {
        address += $('#cidade option:selected').val();
    }

    if ($('#estado option:selected').val() != '') {
        address += ' ' + $('#estado option:selected').val();
    }

    if ($('#cep').val() != '') {
        address += ' ' + $('#cep').val();
    }

    geocoder.geocode({
        'address': address
    }, function (results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
            lat = results[0].geometry.location.lat();
            lng = results[0].geometry.location.lng();

            return {
                lat: lat,
                lng: lng
            };
        } else {
            alert("Não foi possivel obter localização: " + status);
        }
    });
}

function getCities(state) {
    $.ajax({
        type: 'POST',
        url: baseurl_ajax,
        data: {
            action: 'getCities',
            state: state
        },
        beforeSend: function () {
            $('#cidade').prop('disabled', 'disabled');
        },
        success: function (response) {
            $('#cidade').prop('disabled', false);
            $('#cidade').empty();
            $('#cidade').append('<option value="">Selecione</option>');
            $.each(response, function (key, city) {
                $('#cidade').append('<option value="' + city + '">' + city + '</option>');
            });
        },
        error: function (response) {
            console.log('Erro');
        }
    }).done(function () {
        $('#cidade').prop('disabled', false);
    }).error(function () {
        $('#cidade').prop('disabled', false);
    });
}

/*
 *  document ready
 *
 *  This function will render each map when the document is ready (page has loaded)
 *
 *  @type	function
 *  @date	8/11/2013
 *  @since	5.0.0
 *
 *  @param	n/a
 *  @return	n/a
 */
// global var
var map = null;

$(document).ready(function () {


    $('.acf-map').each(function () {
        map = new_map($(this));
    });

});

[
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "featureType": "administrative",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd"
      }
    ]
  },
  {
    "featureType": "landscape",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "poi",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.business",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi.government",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi.medical",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "poi.place_of_worship",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi.school",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi.sports_complex",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dadada"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "transit",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "water",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#c9c9c9"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  }
]