var totalPosts ;
var opcaoContato = '';

$(document).ready(function () {
      

    jQuery('#contato-form-container, #ofereca-terreno-form-container, #contato-form-container-alt, #solicite-contato-form-container').on('submit',function(event) {
        event.preventDefault();
    });

    if ($('body').hasClass('page-id-14')) {
        //totalPosts = -1;
        //ajaxLancamentos($.cookie('regiao'));
    }
    if ($('div').hasClass('map-empreendimento')) {
        //initMap();
    }
    if ($('div').hasClass('slide-principal')) {
        $('.slide-principal').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            fade: true,
            dots: true,
            asNavFor: '.slide-navegacao',
            adaptiveHeight: true
        });
        $('.slide-navegacao').slick({
            slidesToShow: 7,
            slidesToScroll: 1,
            asNavFor: '.slide-principal',
            dots: false,
            arrows: false,
            centerMode: false,
            focusOnSelect: true,
            responsive: [
                {
                  breakpoint: 800,
                  settings: {
                    slidesToShow: 4,
                    infinite: false,
                  }
                },
                {
                    breakpoint: 425,
                    settings: {
                        slidesToShow: 3,
                        infinite: false,
                    }
                }       
            ]
        });
    }

    if ($('body').hasClass('page-template-page-trabalhe-conosco')) {
        //initDropZone();
    }

    if ($('body').hasClass('page-template-page-seja-um-corretor')) {
        //initDropZone();
    }

    if($('body').hasClass('page-template-search')){
        //ajaxCamposBusca($.cookie('regiao'));
    }

    if ($('body').hasClass('home')) {

        setTimeout(function () {
            var alturaPaiDestaque = $('#slide-destaque .slick-slide').height()
            $('#slide-destaque .item').css('height', alturaPaiDestaque);

            var alturaPaiProntoMorar = $('#slide-pronto-morar .slick-slide').height()
            $('#slide-pronto-morar .item').css('height', alturaPaiProntoMorar);

            if (document.cookie.indexOf("regiao") == -1) { // TODO: VERIFICAR E APAGAR
            }else {
                totalPosts = 15;
            }
        }, 500);
    }

    setTimeout(function () {
        if (jQuery.cookie('regiao') == 'sp') {
            $('#estado-sp').addClass('active');
        } else if (jQuery.cookie('regiao') == 'rj') {
            $('#estado-rj').addClass('active');
        }
    }, 500);

    $('#filial-rj, #estado-rj').on('click', function () {
        $('#estado-rj').addClass('active');
    });

    $('#filial-sp, #estado-sp').on('click', function () {
        $('#estado-sp').addClass('active');
    });

    $('.estado').on('click', function () {
        location.reload();
    });

    $('#slide-home-source').slick({
        dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: false
        //centerMode: true,
    });

    $('#slide-principal-empreendimento').slick({
        dots: true,
        arrows: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true
        //centerMode: true,
    });

    $('.slide-imagem-empreendimento ').slick({
        dots: false,
        arrows: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        responsive: [{
            breakpoint: 576,
            settings: {
                arrows: false,
                dots:true
            }
        }]
        //centerMode: true,
    });

    $('.slide-plantas-empreendimento').slick({
        dots: false,
        arrows: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        responsive: [{
            breakpoint: 576,
            settings: {
                arrows: false,
                dots:true
            }
        }]
        //centerMode: true,
    });

    $('.slide-fotos-empreendimento').slick({
        dots: false,
        arrows: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        responsive: [{
            breakpoint: 576,
            settings: {
                arrows: false,
                dots:true
            }
        }]
        //centerMode: true,
    });

    /** slide conteúdo das páginas internas **/
    $('.box-slide').slick({
        dots: true,
        arrows: true,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [{
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
    });

    
    /*** clear busca avançada ***/
    $('.clear-fields').on('click', function () {
        $('.form-busca-avancada form').trigger("reset");
    });

    /** Menu Mobile **/

    $('.menu_toggle').on('click', function () {
        $(this).toggleClass('on');
        $('.bloco-links').toggleClass('on');
        $('#header').toggleClass('on');
    });

    /** Fim - Menu Mobile **/


    /** Máscara para telefone **/

    var SPMaskBehavior = function (val) {
        return val.replace(/\D/g, '').length === 9 ? '(00)00000-0000' : '(00)0000-00009';
    },
    spOptions = {
        onKeyPress: function (val, e, field, options) {
            field.maskk(SPMaskBehavior.apply({}, arguments), options);
        }
    };

    $('.sp_celphones').maskk(SPMaskBehavior, spOptions);
    $('.ddd-mask').maskk('00');
    $('.wpp-mascara').maskk('(00)00000-0000');
    $('.tel-mascara').maskk('(00)0000-0000');

    /** Fim - Máscara para telefone **/

    /** Máscara CPF/CNPJ **/


    /** Fim - Máscara CPF/CNPJ **/

    /** Validação CPF/CNPJ **/

    $('.cpf').maskk('000.000.000-00', {reverse: true});
    $('.cpf').blur(function(){
        var cpf = $('.cpf').val();
        if(!(validaCPF(cpf))){
            $(".btn-contato").attr("disabled", "disabled");
            alert('CPF inválido');
        } else {
            $(".btn-contato").removeAttr("disabled");
        }
    });

    $('#relacaoInvestidores input[name=cpfCnpj]').on('change', function() {
        //alert($('input[name=cpfCnpj]:checked', '#relacaoInvestidores').val()); 
        if($('input[name=cpfCnpj]:checked', '#relacaoInvestidores').val()=='cpf'){
            $('.inputCpfCnpf').addClass('cpf');
            $('.inputCpfCnpf').removeClass('cnpj');
            $('.cpf').maskk('000.000.000-00', {reverse: true});
            $('.cpf').blur(function(){
                var cpf = $('.cpf').val();
                if(!(validaCPF(cpf))){
                    $(".btn-contato").attr("disabled", "disabled");
                    alert('CPF inválido');
                } else {
                    $(".btn-contato").removeAttr("disabled");
                }
            });
        } else {
            $('.inputCpfCnpf').addClass('cnpj');
            $('.inputCpfCnpf').removeClass('cpf');
            $('.cnpj').maskk('00.000.000/0000-00', {reverse: true});
            $('.cnpj').blur(function(){
                var cnpj = $('.cnpj').val();
                if(!(validarCNPJ(cnpj))){
                    $(".btn-contato").attr("disabled", "disabled");
                    alert('CNPJ inválido');
                } else {
                    $(".btn-contato").removeAttr("disabled");
                }
            });
        }
     });

    function validaCPF(cpf){
            cpf = cpf.replace('.','');
            cpf = cpf.replace('.','');
            cpf = cpf.replace('-','');
             
            erro = new String();
            if (cpf.length < 11) erro += "Sao necessarios 11 digitos para verificacao do CPF! \n\n"; 
            var nonNumbers = /\D/;
            if (nonNumbers.test(cpf)) erro += "A verificacao de CPF suporta apenas numeros! \n\n";  
            if (cpf == "00000000000" || cpf == "11111111111" || cpf == "22222222222" || cpf == "33333333333" || cpf == "44444444444" || cpf == "55555555555" || cpf == "66666666666" || cpf == "77777777777" || cpf == "88888888888" || cpf == "99999999999"){
                  erro += "Numero de CPF invalido!";
            }
            var a = [];
            var b = new Number();
            var c = 11;
            for (i=0; i<11; i++){
                a[i] = cpf.charAt(i);
                if (i <  9) b += (a[i] *  --c);
            }
            if ((x = b % 11) < 2) { a[9] = 0; } else { a[9] = 11-x; }
            b = 0;
            c = 11;
            for (y=0; y<10; y++) b += (a[y] *  c--); 
            if ((x = b % 11) < 2) { a[10] = 0; } else { a[10] = 11-x; }
            status = a[9] + ""+ a[10];
            if ((cpf.charAt(9) != a[9]) || (cpf.charAt(10) != a[10])){
                erro +="Digito verificador com problema!";
            }
            if (erro.length > 0){
                return false;
            }
            return true;
    }

    function validarCNPJ(cnpj) {
 
        cnpj = cnpj.replace(/[^\d]+/g,'');
     
        if(cnpj == '') return false;
         
        if (cnpj.length != 14)
            return false;
     
        // Elimina CNPJs invalidos conhecidos
        if (cnpj == "00000000000000" || 
            cnpj == "11111111111111" || 
            cnpj == "22222222222222" || 
            cnpj == "33333333333333" || 
            cnpj == "44444444444444" || 
            cnpj == "55555555555555" || 
            cnpj == "66666666666666" || 
            cnpj == "77777777777777" || 
            cnpj == "88888888888888" || 
            cnpj == "99999999999999")
            return false;
             
        // Valida DVs
        tamanho = cnpj.length - 2;
        numeros = cnpj.substring(0,tamanho);
        digitos = cnpj.substring(tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (i = tamanho; i >= 1; i--) {
          soma += numeros.charAt(tamanho - i) * pos--;
          if (pos < 2)
                pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(0))
            return false;
             
        tamanho = tamanho + 1;
        numeros = cnpj.substring(0,tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (i = tamanho; i >= 1; i--) {
          soma += numeros.charAt(tamanho - i) * pos--;
          if (pos < 2)
                pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(1))
              return false;
               
        return true;
        
    }

    /** Fim - Validação CPF/CNPJ **/


    $('.scrollLink').click(function () {
        $('html, body').animate({
            scrollTop: $($.attr(this, 'href')).offset().top
        }, "slow");
        return false;
    });

    $('.scrollLinkMore').click(function () {
        $('html, body').animate({
            scrollTop: $($.attr(this, 'href')).offset().top - 100
        }, "slow");
        return false;
    });

    $('#menu-institucional .menu-item-103 a').click(function () {
        $('html, body').animate({
            scrollTop: $('#missao').offset().top - 125
        }, "slow");
        return false;
    });

    var section = getParameterByName("section");
    if (section != '') {
        $('html, body').animate({
            scrollTop: $("#" + section).offset().top - 125
        }, 500);
    }

    var form = getParameterByName("form");
    if (form != '') {
        $('#'+form).modal({
            show: true
        });
    }

    jQuery("#filial-rj, #estado-rj").on("click", function () {
        $.removeCookie('regiao', { path: '/' });
        $.removeCookie('regiao', { path: '/empreendimentos' });
        jQuery.cookie('regiao', 'rj', { path: '/' });
        totalPosts = -1;
        ajaxLancamentos('rj');
    });

    jQuery("#filial-sp, #estado-sp").on("click", function () {
        $.removeCookie('regiao', { path: '/' });
        $.removeCookie('regiao', { path: '/empreendimentos' });
        jQuery.cookie('regiao', 'sp', { path: '/' });
        totalPosts = -1;
        ajaxLancamentos('sp');
    });

    /***** mudar de posição plugin de compartilhamento *****/
    setTimeout(function () {
        $(".entry-content .sharedaddy").clone().appendTo("#compartilhamentos");
        $(".entry-content .sharedaddy").remove();
    }, 3000);

    /***** auto complete estados cidades de form *****/
    var baseurl = window.location.origin;
    $.getJSON(baseurl + '/wp-content/themes/pullup-theme/estados_cidades.json', function (data) {

        var options = '<option value="">Estado</option>';
        $.each(data, function (key, val) {
            options += '<option value="' + val.sigla + '">' + val.nome + '</option>';
        });
        $("#estado").html(options);

        $("#estado").change(function () {

            var options_cidades = '<option value="">Cidade</option>';
            var str = "";

            $("#estado option:selected").each(function () {
                str += $(this).text();
            });

            $.each(data, function (key, val) {
                if (val.nome == str) {
                    $.each(val.cidades, function (key_city, val_city) {
                        options_cidades += '<option value="' + val_city + '">' + val_city + '</option>';
                    });
                }
            });

            $("#cidade").html(options_cidades);

        }).change();

    });

    if($("body").hasClass('single-empreendimento')){

        $('.slide-abre-modal .slick-slide').on('click', function () {
            var slickItemParent = $(this).children().children().children();
            var slickItem = $(slickItemParent).data("target");
            var dataSlickIndex = Number($(this).data("slick-index"));
            //var categoriaSlug = slickItem.data("cat");

            //console.log(slickItem.data("target"));
    
     
            setTimeout(function () {           
                $(slickItem+'Slide').slick({
                    dots: false,
                    arrows: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    adaptiveHeight: true,
                    responsive: [{
                        breakpoint: 576,
                        settings: {
                            arrows: false,
                            dots:true
                        }
                    }]
                    //centerMode: true,
                });
            }, 50);
    
            setTimeout(function () {
                $(slickItem+'Slide').slick('slickGoTo', dataSlickIndex, true);
            }, 300);
    
        });        

    }

    if($("body").hasClass('page-template-page-institucionais')){
        var baseUrl = window.location.origin;
        if($.cookie('institucional_hc')){
            $.removeCookie('institucional_hc');
            window.location.href = baseUrl+'/ri-downloads';
        }
    }

    
    setTimeout(function () {
        $("#hcrmAlertBox").remove();
    }, 1000);

    $('.barra-flutuante').on('click', function() {
        if($('.barra-flutuante').hasClass('small')){
            $('.barra-flutuante').removeClass('small');
            $('.barra-flutuante').toggleClass('active').focus();
        } else {
            $('.barra-flutuante').removeClass('active').addClass('small');
        }
    });

    $('#slide-destaque').slick({
        dots: true,
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [{
            breakpoint: 993,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false
            }
        }
        ]
    });

    $('#slide-pronto-morar').slick({
        dots: true,
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [{
            breakpoint: 993,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false
            }
        }
        ]
    });

    setTimeout(function() {
        $('#nf-field-145').on('click', function () {
            var Key = 'F4ed7OoWvG01';
            var KeyIntegradora = '1D5C7C44-4F49-4AA9-9DC3-92589FC6D86C';
            var KeyAgencia = '1D5C7C44-4F49-4AA9-9DC3-92589FC6D86C';
            var CampanhaKey = 'F6a2R3gZwuY1';
            //var CampanhaKey = 'xwj8XM217lA1';
            var ProdutoKey = document.getElementById('ProdutoKey').value;
            var CanalKey = '6ubYqh4KFQY1';
            var Midia = "Hotsite";
            var Peca = "";
            var Obs = "";
            var UsuarioEmail = "";
            var strMostrarTopo=true;
            var strAutoSubmit=true;
            var strUsarDadosAnteriores=true;
            var emailobrigatorio=true;
            var telefoneobrigatorio=false ;

            var nome = $("#nf-field-140").val(),
                    celular = $("#nf-field-143").val().replace(/[^\d]+/g, ''),
                    ddd_cel = celular.length > 9 ? celular.substring(0, 2) : '',
                    telefone = $("#nf-field-141").val().replace(/[^\d]+/g, ''),
                    ddd = telefone.length > 9 ? telefone.substring(0, 2) : '',
                    email = $("#nf-field-142").val(),
                    dados = {
                        "Key": Key,
                        "TagAtalho": "",
                        "CampanhaKey": CampanhaKey,
                        "ProdutoKey": ProdutoKey,
                        "CanalKey": CanalKey,
                        "Midia": Midia,
                        "Peca": Peca,
                        "GrupoPeca": "",
                        "CampanhaPeca": "",
                        "PessoaNome": nome,
                        "PessoaSexo": "",
                        "PessoaEmail": email,
                        "PessoaTelefones": [{
                            "Tipo": "OUTR",
                            "DDD": ddd,
                            "Numero": ddd.length > 0 ? telefone.substring(2, telefone.length) : telefone,
                            "Ramal": null
                        }],
                        "Observacoes": Obs,
                        "UsuarioEmail": UsuarioEmail,
                        "Status": "",
                        "KeyExterno": "",
                        "KeyIntegradora": KeyIntegradora,
                        "KeyAgencia": KeyAgencia,
                        "StrAutoSubmit" : strAutoSubmit
                    };

                console.log('Objeto enviado', dados);   
                $.ajax({
                    url: 'https://crm.anapro.com.br/webcrm/webapi/integracao/v2/CadastrarProspect',
                    data: dados,
                    crossDomain: true,
                    cache: false,
                    type: 'POST',
                    dataType: 'json',
                    beforeSend: function () {
                        if (nome.length < 1 || email.length < 1) {
                            return false;
                        }
                    },
                    success: function (response) {
                        console.log('Response', response);
                       if (!response.Sucesso) {
                            // alert("Erro: " + d.Mensagem);
                            return true
                        }

                    },
                    error: function (xhr, ajaxOptions, thrownError) {
                        alert("Erro: " + thrownError + " -- " + xhr.status);
                    }
                });

        });
    }, 1000);

    setTimeout(function() {
        $('select').on('click', function(){
            if($(this).hasClass('active')){
                $(this).removeClass('active');
            } else {
                $(this).addClass('active');
            }
        });
    
        $('select').blur(function(){
            $(this).removeClass('active');
        });
    }, 1000);

    setTimeout(function() {
        $('select').each(function(){
            var $this = $(this), numberOfOptions = $(this).children('option').length;
        
            $this.addClass('select-hidden'); 
            $this.wrap('<div class="select"></div>');
            $this.after('<div class="select-styled"></div>');
        
            var $styledSelect = $this.next('div.select-styled');
            $styledSelect.text($this.children('option').eq(0).text());
        
            var $list = $('<ul />', {
                'class': 'select-options'
            }).insertAfter($styledSelect);
        
            for (var i = 0; i < numberOfOptions; i++) {
                $('<li />', {
                    text: $this.children('option').eq(i).text(),
                    rel: $this.children('option').eq(i).val()
                }).appendTo($list);
            }
        
            var $listItems = $list.children('li');
        
            $styledSelect.click(function(e) {
                e.stopPropagation();
                $('div.select-styled.active').not(this).each(function(){
                    $(this).removeClass('active').next('ul.select-options').hide();
                });
                $(this).toggleClass('active').next('ul.select-options').toggle();
            });
        
            $listItems.click(function(e) {
                e.stopPropagation();
                $styledSelect.text($(this).text()).removeClass('active');
                $this.val($(this).attr('rel'));
                $list.hide();
                //console.log($this.val());
            });
        
            $(document).click(function() {
                $styledSelect.removeClass('active');
                $list.hide();
            });
        
        });
    }, 100);

});

function animateScrollEmp(toScroll) {
    jQuery('html, body').animate({
        scrollTop: jQuery(toScroll).offset().top - 100
    }, 1000)
}

function scrollToDiv(toScroll) {
    jQuery('html, body').animate({
        scrollTop: jQuery(toScroll).offset().top
    }, 1000)
}

var map;
function initMap() {
    
    lat = jQuery('#map').data('lat');
    lng = jQuery('#map').data('lng');
    position = { lat: lat, lng: lng}
    map = new google.maps.Map(document.getElementById('map'), {
        center: position,
        zoom: 16
    });
    var marker = new google.maps.Marker({
        position: position,
        map: map
    });
}

function getParameterByName(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    var results = regex.exec(location.search);

    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

   
$(window).scroll(function () {
    if ($(window).scrollTop() > 10) {
        $('#header').addClass('menu-fixo');
        $('#slide-home').addClass('menu-fixo');
        $('#breadcrumb').addClass('menu-fixo');
    } else {
        $('#header').removeClass('menu-fixo');
        $('#slide-home').removeClass('menu-fixo');
        $('#breadcrumb').removeClass('menu-fixo');
    }
});

function initDropZone (){
    var dropzone = $(".dropzone")[0];
    var inputfile = $(".inputFile")[0];
    var labelinput = $(".labelinput")[0];
    var labelinputlink = $(".labelinput-link")[0];

    inputfile.oninput = function(e){
        labelinput.innerHTML = 'Clique ou arraste seu currículo para enviar o arquivo';
        labelinputlink.innerHTML ='Arquivo: ' + e.srcElement.files.item(0).name+'';
    }

    dropzone.ondrop = function(e){
        e.preventDefault();
        this.className = 'dropzone';
        if (e.dataTransfer.files.length > 1) {
            labelinput.innerHTML = 'Favor selecionar apenas um arquivo.';
        } else if(e.dataTransfer.files.item(0).type != 'application/pdf'){
            labelinput.innerHTML = 'Favor selecionar um arquivo do tipo PDF.';
        }
        else {
            inputfile.files = e.dataTransfer.files;
            labelinput.innerHTML = 'Clique ou arraste seu currículo para alterar o arquivo'
            labelinputlink.innerHTML = 'Arquivo: '+ e.dataTransfer.files.item(0).name+'';
        }
    }

    dropzone.ondragover = function(){
        this.className = 'dropzone dragover';
        return false;
    }

    dropzone.ondragleave = function(){
        this.className = 'dropzone';
        return false;
    }
}

function showSearch(){
    $('#modal-search').removeClass('out');
    $('#modal-search').addClass('open');
    $('body').addClass('modal-active');
}

function closeSearch(){
    $('#modal-search').addClass('out');
    //$('#modal-search').removeClass('open');  
    $('body').removeClass('modal-active'); 
}

function showPlayer(){
    $('.modal-player').removeClass('out');
    $('.modal-player').addClass('open');
    $('body').addClass('modal-active');
}

function closePlayer(){
    $('.modal-player').addClass('out');
    $('#yvideo').attr('src', $('#yvideo').attr('src'));
    $('body').removeClass('modal-active'); 
}

function showImagem(imgModal){
    console.log(imgModal);
    $('.modal-imagem').removeClass('out');
    $('.modal-imagem').addClass('open');
    $('body').addClass('modal-active');
    $('.bloco-imagem-modal').html('');
    var htmlModal = '<img id="imagem_modal_plantas" class="imagem" src="'+ imgModal +'" alt="Perspectiva ilustrada planta."></img>'
    $('.bloco-imagem-modal').append(htmlModal);
}

function closeImagem(){
    $('.modal-imagem').addClass('out');
    $('body').removeClass('modal-active'); 
}